/**
 * Whether the user's device supports fine-grained pointer
 */
export const supportsHover = matchMedia('(hover: hover)').matches

/**
 * Check whether the current browser supports a given selector
 *
 * @param {string} selector
 * @returns {boolean}
 */
export function supportsSelector(selector) {
  let style = document.createElement('style')
  document.head.appendChild(style)
  let sheet = style.sheet

  try {
    sheet.insertRule(`${selector} {}`)
    return true
  } catch {
    return false
  } finally {
    style.remove()
  }
}
