import './jquery.search.js'

$(function () {
  let overlayClickHandler
  $('.header-wrap').Search({
    context: '.search-form',
    onToggle(state) {
      if (state) {
        $('header.main').Menu('close')

        let context = this.$context.get(0)

        overlayClickHandler = event => {
          if (!context.contains(event.target)) {
            this.close()
          }
        }

        setTimeout(() => {
          $('body').on('click', overlayClickHandler)
        }, 0)
      } else {
        $('body').off('click', overlayClickHandler)
      }
    },
  })
})
