import './modules/jquery.global-dollar.js'
import './modules/search.js'
import './modules/focus-visible-fallback.js'
import { MobileMenu } from './modules/MobileMenu.js'
import './modules/desktop-menu.js'
import './modules/woocommerce/single-product.js'

$(() => {
  /*******************************
   * Mobile Menu
   ******************************/

  const mobileMenu = new MobileMenu({})

  /*******************************
   * resizeEnd Event (https://stackoverflow.com/a/12692647/12404191)
   * Example usage:
   *
   * $(window).bind('resizeEnd', function() {
   * 		//do something, window hasn't changed size in 500ms
   * });
   ******************************/

  $(window).resize(function () {
    if (this.resizeEndTimeout) clearTimeout(this.resizeEndTimeout)
    this.resizeEndTimeout = setTimeout(function () {
      $(window).trigger('resizeEnd')
    }, 500)
  })

  /*******************************
   *  --vh CSS variable
   *  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   ******************************/

  function calculateVh() {
    var vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', vh + 'px')
  }

  calculateVh()

  $(window).on('resizeEnd', function () {
    calculateVh()
  })

  /*******************************
   *  --scrollbar-width CSS variable
   ******************************/

  function calculateScrollbarWidth() {
    var scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + 'px')
  }

  calculateScrollbarWidth()

  $(window).on('resizeEnd', function () {
    calculateScrollbarWidth()
  })

  /*******************************
   * Dismissables
   ******************************/

  const dismissableAttribute = 'data-dismissable'
  const dismissedClass = 'dismissed'
  const initializedClass = 'initialized'
  const dismissables = $(`[${dismissableAttribute}]`)

  dismissables.each((i, element) => {
    const $element = $(element)
    $element.addClass(initializedClass)

    const key = $element.attr(dismissableAttribute)
    const localStorageKey = `dismissable-dismissed__${key}`
    const dismissButton = $element.find('.dismiss-button')

    const isDismissed = Boolean(localStorage.getItem(localStorageKey))
    if (isDismissed) $element.addClass(dismissedClass)

    dismissButton.on('click', function () {
      localStorage.setItem(localStorageKey, true)
      $element.addClass(dismissedClass)
    })
  })
})
