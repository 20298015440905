const ANIMATION_DURATION = 400
const LEAVE_DELAY = 400

let visibleMenus = new Set()

function hide($menu) {
  if (!$menu.hasClass('visible')) return

  $menu.removeClass('visible')
  visibleMenus.delete($menu[0])

  $menu.animate(
    { height: 0 },
    {
      duration: ANIMATION_DURATION,
    },
  )
}

$(() => {
  $('header.desktop .menu > .menu-item-has-children > a')
    .next('.sub-menu')
    .css('height', 0)
    .end()
    .addClass('js-hover')
    .on('mouseenter', function () {
      let $menu = $(this).next('.sub-menu')

      if ($menu.hasClass('visible')) return

      for (let visibleMenu of visibleMenus) {
        hide($(visibleMenu))
      }

      $menu.addClass('visible')
      visibleMenus.add($menu[0])

      let height = $menu.css('height', 'auto').height()
      $menu.css('height', 0)

      $menu.animate(
        { height },
        {
          duration: ANIMATION_DURATION,
          complete() {
            $menu.css('height', 'auto')
          },
        },
      )
    })
    .closest('li')
    .on('mouseenter', function () {
      let $menu = $(this).children('.sub-menu')
      if (!$menu.hasClass('visible')) return

      clearTimeout($(this).data('leave-timeout'))
    })
    .on('mouseleave', function () {
      let $menu = $(this).children('.sub-menu')
      if (!$menu.hasClass('visible')) return

      let timeout = setTimeout(() => {
        hide($menu)
      }, LEAVE_DELAY)
      $(this).data('leave-timeout', timeout)
    })

  $(window).on('mouseleave', function (event) {
    if (event.target !== window) return

    for (let visibleMenu of visibleMenus) {
      hide($(visibleMenu))
    }
  })
})
