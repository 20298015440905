class MobileMenu {
  active = false
  defaultOptions = {
    menuButtonSelector: '.menu-button',
    menuActiveClass: 'mobile-menu-active',
  }

  constructor(options) {
    this.options = { ...this.defaultOptions, ...options }
    this.menuButton = document.querySelector(this.options.menuButtonSelector)

    this.menuButton.addEventListener('click', () => {
      this.toggle()
    })
  }

  open() {
    this.active = true
    this.applyOpenState()
  }

  close() {
    this.active = false
    this.applyOpenState()
  }

  toggle() {
    this.active = !this.active
    this.applyOpenState()
  }

  applyOpenState() {
    let { menuActiveClass } = this.options
    if (this.active) {
      document.body.classList.add(menuActiveClass)
    } else {
      document.body.classList.remove(menuActiveClass)
    }
  }
}

export { MobileMenu }
