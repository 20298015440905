$(() => {
  $('.single-product .variations #pa_sizes').each(function () {
    let $select = $(this)
    let $row = $select.closest('tr')

    if ($row.parent().children().length === 1) {
      $row.closest('table').hide()
    } else {
      $row.hide()
    }

    let $sizesContainer = $('.product-sizes-container')
    if ($sizesContainer.length > 0) {
      let options = $select
        .children('option[value]')
        .get()
        .filter(option => option.value)
        .map(option => [option.value, option.innerText])

      let buttons = options.map(([value, text]) => {
        return $('<button />')
          .text(text)
          .addClass('size-button')
          .toggleClass('active', value === $select.val())
          .on('click', function () {
            $select.val(value).trigger('change')

            for (let $button of buttons) {
              $button.removeClass('active')
            }
            $(this).addClass('active')
          })
      })

      $sizesContainer.append(buttons)
    }
  })
})
