import './jquery.find-and-self.js'

import monomitter from 'monomitter'
import { trapper } from '@js/util/trapper'

class Search {
  static defaults = {
    bodyToggleClass: 'toggled-search',
    form: '.search-form',
    input: '.main-search',
    toggleButton: '.search-button, .search-button, .mob-search-button',
  }

  constructor(element, options) {
    this.options = options
    let [triggerToggle, subscribeToggle] = monomitter()
    this.triggerToggle = triggerToggle

    this.$element = $(element)
    this.$body = $(document.body)
    this.$toggleButton = this.$element.findAndSelf(options.toggleButton)
    this.$form = this.$element.findAndSelf(options.form)
    this.$context = options.context
      ? this.$element.findAndSelf(options.context)
      : this.$form
    this.$input = this.$element.findAndSelf(options.input)

    if (typeof options.onToggle === 'function') {
      subscribeToggle(options.onToggle.bind(this))
    }

    this.escapeKeyListener = event => {
      if (event.key === 'Escape' && this.isOpen()) {
        event.stopPropagation()
        this.close()
      }
    }

    this.init()
  }

  isOpen() {
    return this.$body.hasClass(this.options.bodyToggleClass)
  }

  toggle() {
    if (this.isOpen()) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (this.isOpen()) {
      return
    }

    this.untrap = trapper(this.$context.get(0))
    this.$body.addClass(this.options.bodyToggleClass)
    this.triggerToggle(true)
    $(document).on('keydown', this.escapeKeyListener)

    this.$input.focus()
  }

  close() {
    if (!this.isOpen()) {
      return
    }

    this.untrap()
    this.$body.removeClass(this.options.bodyToggleClass)
    this.triggerToggle(false)
    $(document).off('keydown', this.escapeKeyListener)
  }

  init() {
    // Toggle Button
    this.$toggleButton.click(() => {
      this.toggle()
    })
  }
}

$.fn.Search = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('Search')
    let options = $.extend(
      {},
      Search.defaults,
      $this.data(),
      typeof option == 'object' && option,
    )

    if (!data) {
      $this.data('Search', new Search(this, options))
    } else {
      switch (option) {
        case 'toggle':
        case 'open':
        case 'close':
        case 'isOpen':
          return $this.data('Search')[option]()
      }
    }
  })
}
